import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_PLAN,
  brazilianStates,
  ShopPageReturnParam,
  ShopPages,
  targetOptions,
} from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import ShopActionButton from "../../../components/buttons/shop-action-button/shop-action-button";
import ShopTextButton from "../../../components/buttons/shop-text-button/shop-text-button";
import ShopSelect from "../../../components/inputs/shop-select/shop-select";
import ShopHeading, {
  Variant,
} from "../../../components/text/shop-heading/shop-heading";
import { selectIsLogged } from "../../../features/auth/slice";
import ShopService from "../../../features/shop/service";
import {
  addCartItem,
  selectDiscountCode,
  selectProducts,
} from "../../../features/shop/slice";
import { Product } from "../../../features/shop/type";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import ShopSpacer from "../components/shop-spacer/shop-spacer";

const PlanSelect: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector(selectIsLogged);
  const products: Product[] = useSelector(selectProducts);
  const selectedCode = useSelector(selectDiscountCode);
  const [selectedState, setSelectedState] = useState(brazilianStates[0]);
  const [hasTarget, setHasTarget] = useState(targetOptions[0]);

  const addToCart = async () => {
    const product = products.find(
      (prod) =>
        prod.product_type === ACCESS_PLAN &&
        prod.state === selectedState.value &&
        prod.has_votes_target === hasTarget.value
    );

    dispatch(
      addCartItem({
        service: new ShopService(),
        code: selectedCode,
        cartItem: { product_id: product.id },
      })
    );
  };

  const keepShopping = async () => {
    await addToCart();
    navigate(`/shop/${ShopPages.PRODUCT_SELECT}`);
  };

  const goToCheckout = async () => {
    await addToCart();
    navigate(`/shop/${ShopPages.CART}`);
  };

  const login = () => {
    navigate(`/shop/${ShopPages.LOGIN}?return=${ShopPageReturnParam.PLAN}`);
  };

  return (
    <ShopPageContainer>
      <ShopHeading variant={Variant.H1}>
        Siga nosso passo a passo para escolher o seu Plano:
      </ShopHeading>
      <ShopSpacer space={10} />

      <ShopHeading variant={Variant.H2}>
        Você deseja acesso a qual estado?
      </ShopHeading>

      <ShopSelect
        label=""
        className=""
        value={selectedState}
        options={brazilianStates}
        placeHolder={"Selecione um estado"}
        isLoading={false}
        onChange={(state) => setSelectedState(state)}
      />

      <ShopHeading variant={Variant.H2}>
        Deseja incluir meta de votos em seu plano?
      </ShopHeading>
      <ShopSelect
        label=""
        className=""
        value={hasTarget}
        options={targetOptions}
        placeHolder={"Incluir meta?"}
        isLoading={false}
        onChange={(target) => setHasTarget(target)}
      />
      <ShopSpacer space={100} />

      {!isLogged && (
        <ShopActionButton onClick={login}>
          Faça o login ou registre-se para continuar
        </ShopActionButton>
      )}

      {isLogged && (
        <>
          <ShopActionButton onClick={goToCheckout}>
            Prosseguir para o pagamento
          </ShopActionButton>
          <ShopActionButton outline={true} onClick={keepShopping}>
            Adicionar ao carrinho e continuar comprando
          </ShopActionButton>
        </>
      )}

      <ShopSpacer space={10} />
      <ShopTextButton
        onClick={() => navigate(`/shop/${ShopPages.PRODUCT_SELECT}`)}
      >
        {"Cancelar"}
      </ShopTextButton>
    </ShopPageContainer>
  );
};

export default PlanSelect;
