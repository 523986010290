import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Pages,
  ShopPageReturnParam,
  ShopPages,
  Status,
} from "../../../app/constants";
import { AppDispatch } from "../../../app/store";
import LoginCtaButton from "../../../components/buttons/login-cta-button/login-cta-button";
import LoginTextButton from "../../../components/buttons/login-text-button/login-text-button";
import { makeContainer } from "../../../components/container/container";
import LoginCheckboxText from "../../../components/inputs/login-checkbox/login-checkbox";
import LoginInputText from "../../../components/inputs/login-input-text/login-input-text";
import LoginFormLabelText from "../../../components/text/login-form-label-text/login-form-label-text";
import LoginTitleText from "../../../components/text/login-title-text/login-title-text";
import AuthService from "../../../features/auth/service";
import {
  cleanUp,
  selectAuthStatus,
  selectIsLogged,
  signup,
} from "../../../features/auth/slice";
import ShopPageContainer from "../components/shop-page-container/shop-page-container";
import "./signup.css";

const TermsAcceptance = makeContainer("shop-signup-terms-acceptance");
const Signup = makeContainer("shop-signup-signup");

const ShopSignup: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const signupStatus: Status = useSelector(selectAuthStatus);
  const isLogged = useSelector(selectIsLogged);
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [termsRead, setTermsRead] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnParam = queryParams.get("return");

  useEffect(() => {
    if (isLogged) {
      if (returnParam === ShopPageReturnParam.PLAN) {
        navigate(`/shop/${ShopPages.PLANS}`);
      } else {
        navigate(`/shop/${ShopPages.REPORT_TYPE}`);
      }
    }
  }, [navigate, returnParam, isLogged]);

  const signupUser = () => {
    if (signupStatus !== Status.LOADING) {
      const signupParams = {
        data: {
          name: nameInput,
          email: emailInput,
          password: passwordInput,
          rememberMe: false,
        },
        service: new AuthService(),
      };

      dispatch(signup(signupParams));
    }
  };

  function handleClickTerms(link: string) {
    return () => window.open(`/${link}`, "_blank");
  }

  useEffect(() => {
    return () => {
      dispatch(cleanUp(null));
    };
  }, [dispatch]);

  function goToLogin() {
    navigate(`/shop/${ShopPages.LOGIN}?return=${returnParam}`);
  }

  return (
    <ShopPageContainer>
      <LoginTitleText className="shop-signup-title">Cadastre-se</LoginTitleText>
      <LoginFormLabelText className="shop-signup-label">
        Nome*
      </LoginFormLabelText>
      <LoginInputText
        className="shop-signup-input"
        placeHolder="Seu nome completo"
        type="text"
        value={nameInput}
        onChange={(e) => setNameInput(e.target.value)}
      />
      <LoginFormLabelText className="shop-signup-label">
        E-mail*
      </LoginFormLabelText>
      <LoginInputText
        className="shop-signup-input"
        placeHolder="Seu melhor email"
        type="text"
        value={emailInput}
        onChange={(e) => setEmailInput(e.target.value)}
      />
      <LoginFormLabelText className="shop-signup-label">
        Senha*
      </LoginFormLabelText>
      <LoginInputText
        className="shop-signup-input-password"
        placeHolder="Mínimo 8 caracteres"
        type="password"
        value={passwordInput}
        onChange={(e) => setPasswordInput(e.target.value)}
        onEnterPressed={signupUser}
      />

      <TermsAcceptance>
        <LoginCheckboxText
          checked={termsRead}
          onChange={(e) => setTermsRead(e.target.checked)}
        ></LoginCheckboxText>
        <div>
          <span className="shop-signup-signup-text">
            Li e estou ciente dos{" "}
          </span>
          <LoginTextButton onClick={handleClickTerms(Pages.TERMS)}>
            Termos de Uso
          </LoginTextButton>
        </div>
      </TermsAcceptance>
      <LoginCtaButton
        onClick={signupUser}
        status={signupStatus}
        disabled={!termsRead}
      >
        Cadastrar-se
      </LoginCtaButton>
      <Signup>
        <span className="shop-signup-signup-text">Já possui conta? </span>
        <LoginTextButton onClick={goToLogin}>Faça o login</LoginTextButton>
      </Signup>
    </ShopPageContainer>
  );
};

export default ShopSignup;
